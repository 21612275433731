import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Badge from "../../../elements/V2/Badge"
import AddToCartButton, {
  Product,
} from "../../../elements/V2/Buttons/AddToCartButton"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import NumberDisplay from "../../../elements/V2/NumberDisplay"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getUrlFromStoryblokLink } from "@utils/storyblok"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  imageUrl: string
  imageAlt: string
  productName: string
  productDescription: string
  priceValue?: string | number
  priceSuperscriptText?: string
  priceAdornmentText?: string
  priceStrikeSuperscriptText?: boolean
  priceSubscriptText?: string
  priceSideScriptText?: string
  learnMoreButtonUrl?: string
  calloutLabel?: string
  badgeText?: string
  badgeAccentColor?: Color
  isPurchasable?: boolean
  products: Product[]
  bundleId?: string
  bundleName?: string
} & Partial<Storyblok.PillCTA>

const CardMachine = ({
  imageUrl,
  imageAlt,
  productName,
  productDescription,
  priceValue,
  priceSuperscriptText,
  priceAdornmentText = "R",
  priceStrikeSuperscriptText = false,
  priceSideScriptText,
  priceSubscriptText,
  ctaText = "Buy Now",
  ctaLink,
  learnMoreButtonUrl,
  calloutLabel,
  badgeText,
  badgeAccentColor = Color.Orange,
  isPurchasable = false,
  products,
  bundleId,
  bundleName,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  const backgroundColor = Color.LightGray
  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "col-span-4 flex flex-col rounded-20-v2 sm-v2:col-span-2 md-v2:col-span-3 md-v2:rounded-24-v2 lg-v2:col-span-4 lg-v2:rounded-32-v2"
      )}
      {...props}
    >
      <div className="relative flex flex-none items-center justify-center">
        <GatsbyStoryblokImage
          aspectRatio="4:3"
          loadType="eager"
          image={imageUrl}
          alt={imageAlt}
          width={389}
        />
        {badgeText && (
          <Badge
            text={badgeText}
            textColor={getAccentColor(badgeAccentColor)}
            accentColor={badgeAccentColor}
            style="solid"
            className="absolute left-[14px] top-[14px] md-v2:left-20-v2 md-v2:top-20-v2"
          />
        )}
        {calloutLabel && (
          <div className="absolute right-8-v2 top-0 flex h-full items-center md-v2:right-[9px] lg-v2:right-[10px]">
            <Typography
              font="grotesk"
              weight="book"
              size="subscript-lg"
              color={getAccentColor(backgroundColor)}
              text={calloutLabel}
              className="origin-center rotate-180 uppercase opacity-50 [writing-mode:vertical-lr]"
            />
          </div>
        )}
      </div>

      <div className="flex flex-grow flex-col gap-16-v2 px-20-v2 pb-24-v2 pt-8-v2 md-v2:gap-20-v2 md-v2:px-24-v2 md-v2:pb-32-v2 md-v2:pt-16-v2 lg-v2:gap-24-v2 lg-v2:px-32-v2 lg-v2:pb-40-v2">
        <div className="flex-grow">
          <Typography
            font="grotesk"
            weight="medium"
            size="lead-md"
            color={getAccentColor(backgroundColor)}
            text={productName}
            className="mb-4-v2"
          />
          <Typography
            font="grotesk"
            weight="book"
            size="body-md"
            color={getAccentColor(backgroundColor)}
            text={productDescription}
          />
        </div>
        <div className="flex flex-none flex-col gap-16-v2 md-v2:gap-20-v2 lg-v2:gap-24-v2">
          <NumberDisplay
            type="price"
            size="small"
            value={priceValue}
            superscriptText={priceSuperscriptText}
            adornmentText={priceAdornmentText}
            strikeSuperscriptText={priceStrikeSuperscriptText}
            adornmentTextPosition="left"
            textColor={getAccentColor(backgroundColor)}
            subscriptText={priceSubscriptText}
            sideScriptText={priceSideScriptText}
          />
          <div className="flex w-full flex-col items-center gap-16-v2">
            <div className="w-full">
              {isPurchasable ? (
                <AddToCartButton
                  style="solid"
                  bundleId={bundleId}
                  bundleName={bundleName}
                  products={products}
                  buttonColor={Color.Blue}
                  className="!w-full border-[1.5px]"
                />
              ) : (
                ctaLink && (
                  <PillButton
                    hideIcon
                    size="large"
                    text={ctaText}
                    style="bordered"
                    color={Color.Blue}
                    linkUrl={getUrlFromStoryblokLink(ctaLink)}
                    overrideTextColor={getAccentColor(backgroundColor)}
                    className="!w-full border-[1.5px]"
                  />
                )
              )}
            </div>
            {learnMoreButtonUrl && (
              <div>
                <TextButton
                  linkUrl={learnMoreButtonUrl}
                  style="text"
                  text="LEARN MORE"
                  color={getAccentColor(backgroundColor)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardMachine
